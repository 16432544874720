<template>
  <div>
    <div class="archive-edit archive-edit-g">
      <div class="bg-white shadow p-4 rounded-xl max-w-100">
        <div class="flex flex-col items-center justify-between gap-2">
          <ElAlert type="warning" :closable="false" class="rounded-xl">
            <div class="flex items-center gap-3">
              <WarningIcon />
              <p class="w-48">
                {{ $t('archive.state_warning') }}
              </p>
            </div>
          </ElAlert>
          <ElInput class="archive-name" v-model="form.name" :placeholder="$t('archive.state_name')" />
          <ElDatePicker class="archive-date" v-model="form.date_from" type="date" @change="handleDateChange" :clearable="false" style="width: 100%" />
          <button class="save-button bg-blue-200 text-white border-0 w-full" @click="saveAllChanges">
            {{ $t('archive.state_save') }}
          </button>
          <button class="cancel-button border-0 ml-0 w-full" @click="$emit('close')">
            {{ $t('archive.state_cancel') }}
          </button>
        </div>
      </div>
      <div class="object-container-x h-modal z-30 bg-white shadow py-4 rounded-xl" v-loading="loading">
        <ObjectsWrapper @onBack="$emit('close')" />
      </div>
      <UnitsOverlay :visible="isOverlay" class="overlay" />
      <UnitsSaveModal class="save__modal" v-if="saveModal" @onContinue="handleContinue" @onClose="$emit('close')" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, toRaw, computed, watch, onBeforeMount, unref, shallowRef } from 'vue'
import { useStore } from '@/store/store'
import { createVuexHelpers } from 'vue2-helpers'
import sections from '@/enums/newUnits/objects-sections'
import { refs } from '@/components/unitsNew/helpers'

import ObjectsWrapper from '@/components/unitsNew/components/Objects/ObjectsWrapper'
import WarningIcon from '@/components/unitsNew/assets/icons/note-info_warn'
import UnitsOverlay from '@/components/unitsNew/components/UnitsOverlay'
import UnitsSaveModal from '@/components/unitsNew/components/UnitsSaveModal'

const { useGetters, useMutations, useActions } = createVuexHelpers()

const { activeObject, modalType, errors, sensorTableError } = useGetters('units', ['activeObject', 'modalType', 'errors', 'sensorTableError'])
const { SET_ACTIVE_OBJECT, SET_IS_SUBMIT, SET_MODAL_TYPE } = useMutations('units', ['SET_ACTIVE_OBJECT', 'SET_IS_SUBMIT', 'SET_MODAL_TYPE'])
const { UPDATE_UNIT } = useActions('units', ['UPDATE_UNIT'])
const Store = useStore()

const form = ref({
  name: '',
  date_from: ''
})

const localForm = toRaw(form.value);

const data = ref({})
const saveModal = ref(false)
const isOverlay = ref(false)
const loading = ref(false)

const activeTab = computed(() => Store.getters['archive/getActiveTab'])

const handleContinue = () => {
  saveModal.value = false
  isOverlay.value = false

  SET_MODAL_TYPE('edit')
}

const handleSaveModal = (value = false) => {
  saveModal.value = value
  isOverlay.value = value
}

const getIcon = () => {
  if (data.value.icon.name && data.value.icon.color) {
    return {
      key: `${data.value.icon.name}_${data.value.icon.color}`
    }
  }

  return data.value.icon
}

const checkSensorsTableValues = item => {
  const isEmpty = Object.values(item).every(value => value.toString())

  if (isEmpty) {
    return item
  }
}

const getValidValues = section => {
  if (section === sections.SENSORS && activeObject.value[section]) {
    const sensors = activeObject.value[section]

    sensors.forEach(sensor => {
      sensor.param1.calibrationABlist = sensor.param1.calibrationABlist.filter(checkSensorsTableValues)
      sensor.param1.calibrationXYlist = sensor.param1.calibrationXYlist.filter(checkSensorsTableValues)

      sensor.param2.calibrationABlist = sensor.param2.calibrationABlist.filter(checkSensorsTableValues)
      sensor.param2.calibrationXYlist = sensor.param2.calibrationXYlist.filter(checkSensorsTableValues)

      if (sensor.calibrationABlist && sensor.calibrationXYlist) {
        sensor.calibrationABlist = sensor.calibrationABlist.filter(checkSensorsTableValues)

        sensor.calibrationXYlist = sensor.calibrationXYlist.filter(checkSensorsTableValues)
      }
    })
  }
  return (
    activeObject.value[section]?.map(item => {
      if (item.isNew) {
        item.id = null
      }
      return item
    }) || []
  )
}

const getFormData = (method = '', isCreate = false) => {
  const payload = {
    ...data.value,
    icon: getIcon(),
    services: getValidValues('services')
  }

  if (method === 'validate') {
    delete payload.sensors
  }

  payload.sensors = getValidValues('sensors')

  return payload
}

const validateSectionHandler = async () => {
  if (sensorTableError.value && activeTab.value === sections.SENSORS) {
    return instance.proxy.$showError($t('new_units.objects.fields.sensors.fields.errors.save_validation'))
  }

  let isValid = true
  if (refs.sensorTechParametersValidation.value) {
    await refs.sensorTechParametersValidation.value.validate(valid => {
      isValid = valid
    })
  }
}

const editObject = async () => {
  try {
    loading.value = true
    await UPDATE_UNIT(getFormData(''))
    return true
  } catch (error) {
    return false
  } finally {
    loading.value = false
  }
}

const handleDateChange = date => {
  const d = new Date(date)
  const year = d.getFullYear()
  const month = String(d.getMonth() + 1).padStart(2, '0')
  const day = String(d.getDate()).padStart(2, '0')
  form.value.date_from = `${year}-${month}-${day}`
}

const saveAllChanges = async () => {
  try {
    loading.value = true
    const res = await Store.dispatch('archive/editArchiveState', { form: form.value })
    SET_IS_SUBMIT(true)
    await validateSectionHandler()
    if (!errors.value.length) {
      const editSuccess = await editObject()
      if (editSuccess && res) {
        handleSaveModal(true)
      } else {
        throw new Error('Failed to edit object')
      }
    } else {
      throw new Error('Validation errors')
    }
  } catch (e) {
    handleSaveModal(false)
  } finally {
    SET_IS_SUBMIT(false)
    loading.value = false
    await Store.dispatch('units/GET_UNITS');
    await Store.dispatch('archive/getArchiveStates', { id: data.value.id });
  }
}

watch(activeObject, val => {
  data.value = val
})

onBeforeMount(() => {
  if (modalType.value === 'edit') {
    data.value = {
      ...activeObject.value,
      phonenumber: parseInt(activeObject.value.phonenumber),
      phonenumber2: parseInt(activeObject.value.phonenumber2)
    }
  } else {
    SET_ACTIVE_OBJECT(data.value)
  }
})

onBeforeMount(() => {
  form.value = JSON.parse(JSON.stringify(Store.getters['archive/getEditableArchive']));
})
</script>

<style lang="scss" scoped>
.archive-edit {
  ::v-deep(.el-alert) {
    height: 48px;
  }
  ::v-deep(.el-alert__content) {
    padding: 0;
  }
  ::v-deep(.el-alert__description) {
    margin: 0;
    color: #ca5f32;
    font-family: 'Source Sans Pro', serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  ::v-deep(.el-input__inner) {
    border-radius: 8px;
    background-color: #edeff3;
    color: #292929;
    font-size: 15px;
    font-family: 'Source Sans Pro', serif;
    font-weight: 400;
    line-height: 19px;
    text-align: left;

    &::placeholder {
      color: #a6aeb8;
    }

    &:active {
      border-color: #d1dbe4;
    }
  }

  .archive-name {
    margin-top: 6px;
  }

  .archive-date {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .save-button {
    border-radius: 10px;
    font-family: 'Source Sans Pro', serif;
    font-weight: 600;
    font-size: 16px;
    transition: 0.1s;
    height: 44px;
    padding: 9px 20px;
    padding-bottom: 10px;

    &:hover {
      background-color: #2767b6;
      color: white;
    }

    &:focus {
      outline: none;
    }
  }

  .cancel-button {
    border-radius: 10px;
    font-family: 'Source Sans Pro', serif;
    font-weight: 600;
    font-size: 16px;
    transition: 0.1s;
    padding: 9px 20px;
    padding-bottom: 10px;
    height: 44px;
    background: #fff2ec;
    color: #c95726;

    &:focus {
      outline: none;
    }
  }

  ::v-deep(.el-icon-date:before) {
    position: absolute;
    transform: translate(-7px, -2px);
    color: rgba(41, 41, 41, 0.55);
  }
}
</style>

<style lang="scss">
.archive-edit {
  width: calc(100% - 144px);
  min-width: 1172px;
  position: absolute;
  left: 54px;
  top: 20px;
  bottom: 20px;
  overflow-y: auto;
  border-radius: 16px;
  background-color: transparent;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  .overlay__mini {
    left: 250px;
    top: 50px;
    height: 267px;
    width: 316px;
  }
  .overlay {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    height: 100%;
    width: 100%;
  }
  .save__modal {
    margin-left: 168px;
  }
  .cancel-button {
    margin-left: 0 !important;
  }
}

.object-container-x {
  font-family: 'Source Sans Pro', serif;
  width: 800px;
  height: 100%;
  font-size: 14px;
  font-weight: 600;
  color: $main-title-color;

  .divider::after {
    @include grey-divider(0, -1px, 100%, 1px, $light-grey-third-tone-color);
  }

  button:focus {
    outline: none;
  }

  .vertical-line {
    height: calc(100% - 140px);
    width: 1px;
    color: $light-grey-third-tone-color;
  }

  .input-label {
    position: absolute;
    padding-left: 15px;
    padding-top: 3px;
    color: $main-title-color;
    font-weight: 700;
    font-size: 13px;
    z-index: 1;
  }

  .input-label-disabled {
    color: $grey-tone-third-color;
  }

  .el-input input,
  .el-date-editor {
    height: 48px;
  }

  .el-date-editor {
    @include input-type;
    width: 100%;

    padding: 0;

    .el-input__inner {
      padding-left: 15px;
    }

    .el-input__icon {
      display: none;
    }

    .el-range-separator {
      height: auto;
    }

    .el-range-input {
      background: transparent;
      text-align: left;
      width: 38%;
      font-size: 16px;
      color: $main-title-color;
    }
  }

  .el-checkbox {
    &__input {
      border-color: currentColor;
      .el-checkbox__inner {
        border-color: $grey-tone-third-color;
      }
    }

    &.is-checked {
      .el-checkbox__inner {
        border-color: $curios-blue-color;
      }

      .el-checkbox__label {
        color: $main-title-color;
      }
    }
  }

  .el-input,
  .el-textarea {
    &::placeholder {
      color: $grey-tone-third-color;
    }

    input,
    textarea {
      @include input-type;
    }

    .el-select__caret {
      color: $main-title-color;
    }
  }

  .el-select {
    input {
      padding-top: 20px;

      &::placeholder {
        color: $grey-tone-third-color;
      }
    }

    .el-input.is-focus .el-input__inner {
      border-color: transparent;
    }

    .el-input__inner:hover {
      border-color: transparent;
    }
  }

  .el-input.is-disabled .el-input__inner {
    background-color: $light-grey-tone-color;
    color: $grey-tone-third-color;
  }

  .filtration-select .el-input__inner {
    padding-left: 40px;
    padding-top: 10px;
    border-radius: 0;
    font-size: 14px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    border-color: $light-grey-tone-first-color;
    height: 36px;
    background: $light-grey-tone-first-color;
  }

  .sensors-filtration-select .el-input__inner {
    padding-top: 10px;
    border-radius: 0;
    font-size: 14px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    border-color: $light-grey-tone-first-color;
    height: 36px;
    background: $light-grey-tone-first-color;
    border-left: 1px solid #c0c4cc;
  }

  .el-form {
    &-item {
      margin-bottom: 0;

      &__error {
        font-size: 10px;
      }

      &__content {
        line-height: 1.5;
      }
    }
  }

  .tool-icon:hover {
    circle {
      fill: $light-grey-second-tone-color;
    }
  }

  .main-table {
    .el-table__body-wrapper {
      height: calc(100% - 114px);

      .el-table__append-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }

  /** NEW ROOT STYLE */
  .object-sensors__details {
    .el-checkbox {
      &__label {
        font-size: 1rem;
      }
    }

    .el-select {
      width: 100%;
      &-dropdown {
        right: 0;
        left: 0 !important;
        font-family: Lato, sans-serif;
        .el-scrollbar {
          border-radius: 12px;
        }
      }
    }

    .info {
      &-text {
        position: absolute;
        right: 20px;
        width: 295px;
        z-index: 10;
        margin-top: 5px;
        padding: 6px 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #2767b6;
        background: #dbebff;
        border-radius: 6px;
      }

      &:hover .info-text {
        display: block;
      }
    }

    &__types {
      .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        border-radius: 8px;
        background: $light-grey-tone-first-color;
        border: $light-grey-tone-first-color;
        color: $main-title-color;
        box-shadow: none;
      }

      .el-radio-button {
        border: 2px solid $light-grey-second-tone-color;
        padding: 2px;
        border-radius: 12px;

        &__inner {
          border: none;
          border-left: none !important;
          font-size: 13px;
          font-weight: 700;
          line-height: 19px;
          color: $main-title-color;
        }

        &:first-child {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:last-child {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    &__hint {
      border-top: none;
      margin-top: 20px;

      .el-collapse-item__header {
        border-bottom: none;
      }

      &__header {
        border-radius: 12px;
        background: $ligth-blue-color;
        border: 1px solid $ligth-blue-color;
      }

      .el-collapse-item__arrow {
        display: none;
      }

      .el-collapse-item__content::-webkit-scrollbar-thumb {
        background-color: rgba(51, 51, 51, 0.3);
        border-radius: 4px;
      }

      .el-collapse-item__content::-webkit-scrollbar {
        width: 4px;
        height: 8px;
      }

      .el-collapse-item__content::-webkit-scrollbar-track {
        background: $white;
      }

      .isSelected {
        background: $light-grey-second-tone-color;
      }

      .isOpen {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .el-collapse-item__wrap {
        border-bottom: none;

        .el-collapse-item__content {
          padding: 0 15px 15px;
          background: $ligth-blue-color;
          border: 1px solid $ligth-blue-color;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          &::-webkit-scrollbar-track {
            background: $ligth-blue-color;
          }
        }
      }
    }

    &__helpers {
      &--code {
        color: $curios-blue-color;
      }

      &--title {
        color: $main-title-color;
      }
    }

    &__parameters {
      border-top-color: $light-grey-third-tone-color;
      border-bottom-color: $light-grey-third-tone-color;

      .el-collapse-item__header {
        border-bottom: 1px solid $light-grey-third-tone-color;
        font-size: 16px;
        font-weight: 600;
        color: $main-title-color;
        padding: 0 1rem;
      }

      .el-collapse-item__wrap {
        padding: 0 20px;
        overflow: visible;
      }

      .el-collapse-item__arrow {
        display: none;
      }

      .el-collapse-item__header.is-active {
        border-bottom: transparent;
      }

      .el-slider__button-wrapper {
        z-index: 10;

        .el-slider__button {
          border-color: $curios-blue-color;
        }
      }

      .el-slider__bar {
        background: $curios-blue-color;
      }

      .arrow-icon {
        margin-left: 2px;
      }
    }

    &__table {
      border: 1px solid $light-grey-tone-first-color;
      border-radius: 12px;
      padding-bottom: 20px;
      background: $light-grey-tone-color;

      &__switcher {
        &.is-checked .el-switch__core::after {
          margin-left: -13px;
        }

        .el-switch__core {
          width: 27px !important;
          height: 16px;

          &::after {
            width: 12px;
            height: 12px;
          }
        }
      }

      .error {
        border: 1px solid $red-strong-color;

        input {
          color: $red-strong-color;
        }
      }

      .not-valid {
        .el-input__inner {
          color: $red-strong-color;
        }
      }

      .same-value {
        .el-input__inner {
          color: red !important;
        }
      }

      &__content {
        & > div {
          height: 20px;
        }

        .el-input {
          // width: 60px;
          height: 20px !important;
          font-size: 10px !important;

          input {
            // width: 60px;
            height: 20px;
            border-radius: 0;
            background: $white;
            font-weight: 600;
            border: 1px solid $light-grey-third-tone-color;
            padding: 0;
            padding-left: 5px;
          }

          input:focus {
            border-color: $curios-blue-color;
            color: $curios-blue-color;
          }

          input:hover {
            border-color: $curios-blue-color;
          }
        }

        &--remove {
          height: 20px;
          width: 25px;
          border: 1px solid $light-grey-third-tone-color;
        }

        .remove-disabled {
          rect {
            fill: grey;
          }
        }
      }
    }

    &__graphic {
      border: 1px solid $light-grey-tone-first-color;

      .extension:hover {
        text-decoration: underline;
      }
    }

    &__technical__parameters {
      position: relative;
      .el-input {
        input {
          height: 24px;
          padding: 0 10px;
          border-radius: 6px;
          background: white;
          border-color: $light-grey-second-tone-color;
        }
      }
      .el-collapse-item__arrow {
        display: none;
      }

      .el-collapse-item__header {
        padding: 0 20px;
        font-size: 16px;
        font-weight: 600;
      }

      .valueRow {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;

        .labelRow {
          display: inline-block;
          width: 248px;
          font-size: 14px;
        }

        .text-field {
          position: relative;
          width: 132px;
        }

        .valueInput {
          font-size: 14px;
          padding-left: 10px;
          width: inherit;

          &__error {
            position: absolute;
            bottom: -15px;
            right: 0;
            margin-top: 0;
            font-size: 10px;
            color: #c93131;
          }

          &--error {
            border-color: #c93131;
          }
        }

        .deleteIcon {
          cursor: pointer;

          &:hover {
            path {
              fill: #c93131;
            }
          }
        }

        &:nth-child(odd) {
          background-color: #f6f7fa;
        }
      }

      .add-tech-params {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        color: #3a7cca;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;

        .title {
          margin-left: 6px;

          &--grey {
            color: $light-grey-second-tone-color;
          }
        }

        .add-params-icon {
          &--grey {
            circle {
              stroke: $light-grey-second-tone-color;
            }

            path {
              fill: $light-grey-second-tone-color;
            }
          }
        }
      }

      .valueInput {
        border: 1px solid $light-grey-second-tone-color;
        border-radius: 6px;
      }

      .select-tech-fields {
        border-radius: 12px;
        border: 1px solid $light-grey-second-tone-color;
        position: absolute;
        left: 16%;
        width: 368px;
        margin-top: 10px;
        z-index: 10;
        background-color: #edeff3;
        box-shadow: 0px 2px 8px 0px #00000014;

        .select-name {
          padding: 2px 12px;
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;

          &:hover {
            background-color: $light-grey-second-tone-color;
          }
        }
      }

      .disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &__smoothing {
      .el-input input {
        height: 24px;
        border: 1px solid $light-grey-second-tone-color;
        border-radius: 6px;
        padding: 4px 8px;
      }
    }
  }
}
</style>
